import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/boost-home-value-strategic-improvements"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Boosting Your Homes Value with Strategic Improvements.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Thinking about selling your home? The right improvements can significantly boost its
                appeal to potential buyers. But what upgrades deliver the best return on your
                investment?
              </CommonParagraph>
              <CommonParagraph>
                Here at The Selby Team, we're not just here to help you sell—we're your partners in
                achieving your real estate goals. That's why we're covering how strategic
                improvements can enhance your home's value, making it stand out in a competitive
                market so you can secure top dollar and start your next chapter.
              </CommonParagraph>
              <Heading type="h2">Top Value-Increasing Home Improvements</Heading>
              <Heading type="h3">Kitchen Upgrades</Heading>
              <CommonParagraph>
                You may have heard that "the kitchen is the heart of the home." That may be why it's
                such a critical focus area for buyers. The following enhancements may help you get
                competitive offers when it comes{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home/"
                  text="time to sell"
                />
                :
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Appliance upgrades:</strong> Energy-efficient stainless steel models are a
                  big draw.
                </li>
                <li>
                  <strong>Replacing cabinets:</strong> Fresh, neutral tones can make a big impact.
                </li>
                <li>
                  <strong>Adding an island or improving countertops:</strong> Granite and quartz are
                  popular options.
                </li>
              </ul>
              <CommonParagraph>
                Even small changes, such as adding a fresh coat of paint, can elevate your house's
                appeal without breaking the bank.
              </CommonParagraph>
              <Heading type="h3">Bathroom Enhancements</Heading>
              <CommonParagraph>
                Bathrooms are another area of a home where strategic improvements can pay off big
                time. If you're looking for home improvements that increase value, consider:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Adding spa-like amenities:</strong> Think heated flooring, soaking tubs,
                  and rain showers.
                </li>
                <li>
                  <strong>Replacing tiles:</strong> Opt for more contemporary designs.
                </li>
                <li>
                  <strong>Installing a new vanity:</strong> A quick swap can easily create a sleek,
                  modern look.
                </li>
              </ul>
              <Heading type="h3">Exterior Work</Heading>
              <CommonParagraph>
                First impressions matter, and because your home's exterior is the first thing
                they'll see when they come for a showing, don't forget to give it a little TLC. What
                increases home value when it comes to curb appeal? We suggest the following:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Landscaping:</strong> Colorful flowers and trimmed hedges can enhance the
                  yard.
                </li>
                <li>
                  <strong>Exterior lighting:</strong> An illuminated path improves both aesthetics
                  and security.
                </li>
                <li>
                  <strong>New front door:</strong> A new door or a fresh coat of paint on an old one
                  can make the house feel more inviting.
                </li>
              </ul>
              <Heading type="h3">Improving Energy Efficiency</Heading>
              <CommonParagraph>
                One of the best ways to increase home value is to improve energy efficiency.
                Upgrades like:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Energy-efficient doors and windows</li>
                <li>Adding insulation to improve heating and cooling</li>
                <li>Installing smart thermostats</li>
              </ul>
              <CommonParagraph>
                Not only appeal to modern buyers but could also qualify for tax incentives.
              </CommonParagraph>
              <Heading type="h3">Creating More Living Space</Heading>
              <CommonParagraph>
                Last on our list of home improvements that increase value is to create additional
                living space. Whether it's:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Converting a basement or attic into a bedroom</li>
                <li>Building a deck or sunroom</li>
                <li>Adding an accessory dwelling unit (AUD) in the backyard</li>
              </ul>
              <CommonParagraph>
                Buyers are looking for versatile spaces they can further customize to their unique
                needs.
              </CommonParagraph>
              <Heading type="h2">Choosing the Right Improvements</Heading>
              <CommonParagraph>
                Now that you have a better understanding of what increases home value, it's time to
                explore how to choose the right projects for you.{" "}
              </CommonParagraph>
              <Heading type="h3">Consider Market Trends</Heading>
              <CommonParagraph>
                Real estate trends can vary by location, buyer demographics and even{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/best-time-sell-home-san-diego"
                  text="time of the
                year"
                />
                , and San Diego is no exception! Here at The Selby Team, we stay up-to-date on the
                local market to help you prioritize improvements that resonate with Southern
                California buyers.
              </CommonParagraph>
              <Heading type="h3">Know What's In Your Budget</Heading>
              <CommonParagraph>
                Without proper planning, the cost of home improvement projects can quickly add up,
                making it essential to set (and stick to) a realistic budget. Focus on updates that
                offer high returns without stretching you financially. Need some guidance? Our team
                is happy to help you weigh the costs and benefits of different improvements.
              </CommonParagraph>
              <Heading type="h3">Partner With Professionals</Heading>
              <CommonParagraph>
                Working with real estate experts can help ensure your efforts align with your goals.
                We'll not only help you determine which upgrades best suit your needs, but we'll
                take care of staging when you're ready to sell. Our{" "}
                <BlogLink
                  url="https://selbysellssd.com/real-estate-marketing-services"
                  text="Compass Concierge"
                />{" "}
                service can assist with:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Landscaping</li>
                <li>Kitchen improvements</li>
                <li>Interior and exterior painting</li>
                <li>Cosmetic renovations</li>
                <li>And so much more!</li>
              </ul>
              <CommonParagraph>
                Even better? There are no upfront fees—you pay at closing and with no interest.
              </CommonParagraph>
              <Heading type="h2">Let's Get Started</Heading>
              <CommonParagraph>
                We're ready to roll up our sleeves and dive into your home improvement needs so you
                can boost your house's value and appeal.{" "}
                <ContactSlideoutLink text="Connect with us" /> for a seamless upgrade and selling
                experience that prioritizes your real estate goals.
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
